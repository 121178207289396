@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700&display=swap);
.TimedOut_overlay__1VM9p {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.TimedOut_content__3_uLh {
  position: absolute;
  width: 640px;
  height: 416px;
  left: 50%;
  top: 207px;
  transform: translate(-50%, 0%);
  background: var(--white);
  border-radius: 16px;
  outline: none;
}

.TimedOut_container__7Pz9X {
  margin: 72px 120px;
  width: 400px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Text_display__3IfZx.Text_medium__1BXi2 {
  font-size: 42px;
  line-height: 48px;
  color: var(--slate-darken-20);
}

.Text_body__eBwMU.Text_normal__36Rw8 {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-85);
}

.Text_body__eBwMU.Text_small__RmvMe {
  font-size: 14px;
  line-height: 20px;
  color: var(--slate-85);
}

.Text_body__eBwMU.Text_normal__36Rw8.Text_link__DIlB5 {
  color: var(--eden);
  border: none;
  text-decoration: none;
}

.Text_body__eBwMU.Text_small__RmvMe.Text_link__DIlB5 {
  color: var(--eden);
  border: none;
  text-decoration: none;
}

.Text_caption__3mEJg {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
}

.Text_caption__3mEJg.Text_normal__36Rw8 {
  color: var(--slate-85);
}

.Text_caption__3mEJg.Text_error__3X5yG {
  color: var(--slate-darken-20);
}

.Text_caption__3mEJg.Text_link__DIlB5 {
  color: var(--eden);
  border: none;
  text-decoration: none;
}

.Text_header__371OE {
  color: var(--slate-darken-20);
}

.Text_header__371OE.Text_small__RmvMe {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--semibold);
}

.Text_header__371OE.Text_medium__1BXi2 {
  font-size: 24px;
  line-height: 32px;
}

.Text_header__371OE.Text_large__3Lime {
  font-size: 36px;
  line-height: 48px;
}

.Text_header__371OE.Text_medium__1BXi2.Text_regular__3d6Z9 {
  font-weight: normal;
}

.Text_title__3PcfP {
  font-weight: var(--semibold);
  font-size: 14px;
  line-height: 20px;
  color: var(--slate-darken-20);
}

.Text_listBody__PbzjH.Text_small__RmvMe {
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-85);
}

.Text_kicker__10gE_.Text_header__371OE {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.Text_list__1Uo0D.Text_kicker__10gE_ {
  font-weight: bold;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--slate-darken-20);
}

.Text_semibold__3lACb {
  font-weight: var(--semibold);
}

.Text_bold__3sU5A {
  font-weight: var(--bold);
}

.Text_mobile__28cJ9.Text_body__eBwMU {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-darken-20);
}

@keyframes Form_spin__sWBs5 {
  100% {
    transform: rotate(360deg);
  }
}

.Form_field__2EpOt,
.Form_select__2aySK,
.Form_fieldContainer__2Fa_z > input,
.Form_fieldContainer__2Fa_z > input:active,
.Form_fieldContainer__2Fa_z > input:focus {
  --padding-width: 12px;
  --icon-width: 16px;
  --border-width: 1px;
  margin: 0;
  border: var(--border-width) solid var(--slate-15);
  border-radius: 4px;
  color: var(--slate-darken-20);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
  line-height: 20px;
  padding: 10px calc(var(--icon-width) + var(--padding-width)) 10px var(--padding-width);
  width: calc(100% - 2 * var(--padding-width) - 2 * var(--border-width) - var(--icon-width));
  outline: none;
}

.Form_field__2EpOt:focus,
.Form_field__2EpOt:active {
  border: 1px solid var(--apple);
}

.Form_fieldContainer__2Fa_z > input,
.Form_select__2aySK,
.Form_select__2aySK:focus {
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/static/media/chevronDown.e314f4f1.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.Form_select__2aySK.Form_arrowless__3pWVF,
.Form_select__2aySK.Form_arrowless__3pWVF:focus {
  -webkit-appearance: none;
  appearance: none;
  background: white;
  padding: 8px 16px;
  width: 100%;
}

.Form_fieldContainer__2Fa_z > input:hover,
.Form_select__2aySK:hover {
  cursor: pointer;
  background-color: var(--slate-05);
}

.Form_fieldContainer__2Fa_z.Form_fieldContainerOpen__3jm0O > input,
.Form_fieldContainer__2Fa_z.Form_fieldContainerOpen__3jm0O > input:hover {
  background-color: var(--slate-15);
}

.Form_field__2EpOt.Form_error__E8qnR {
  border: 1px solid var(--razz);
  color: var(--razz-darken-20);
}

.Form_field__2EpOt::placeholder {
  color: var(--slate-45);
}

.Form_fieldWithIcon__33y22 {
  display: flex;
  align-items: center;
  position: relative;
}

.Form_fieldWithIcon__33y22 input {
  padding-left: 30px;
  box-sizing: border-box;
  width: 100%;
}

.Form_fieldWithIcon__33y22 svg {
  position: absolute;
  margin-left: 10px;
}

.Form_primaryButtonLoading__RewTv,
.Form_primaryButtonLoading__RewTv:active,
.Form_primaryButtonLoading__RewTv:focus,
.Form_primaryButton__2TI_b,
.Form_primaryButton__2TI_b:active,
.Form_primaryButton__2TI_b:focus {
  font-family: 'Open Sans', sans-serif !important;
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: var(--semibold);
  font-size: 16px;
  line-height: 135%;
  color: white;
  height: 47px;
  width: 100%;
  max-width: 400px;
}

.Form_primaryButton__2TI_b {
  background: var(--eden-gradient);
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
}

.Form_primaryButton__2TI_b:hover {
  cursor: pointer;
}

.Form_primaryButton__2TI_b:disabled {
  background: var(--slate-05);
  color: var(--slate-55);
  cursor: default;
}

.Form_primaryButtonLoading__RewTv {
  background: var(--eden);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.Form_primaryButtonLoading__RewTv .Form_inner__3Hih6 {
  animation: Form_spin__sWBs5 1s linear infinite;
}

.Form_dayPickerOverlayWrapper__4L75b {
  display: flex;
  justify-content: flex-end;
}

.Form_dayPickerOverlay__26WaO {
  position: fixed;
  background: white;
  margin-top: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: var(--slate-darken-20);
  z-index: 1001;
}

.Form_dayPickerOverlayWrapper__4L75b .DayPicker-Day {
  width: 24px;
  height: 24px;
}

.Form_dayPickerOverlayWrapper__4L75b .DayPicker-Caption {
  color: var(--eden-darken-10);
}

.Form_dayPickerOverlayWrapper__4L75b .DayPicker-Day--today {
  color: var(--eden);
}

.Form_dayPickerOverlayWrapper__4L75b .DayPicker-NavButton--prev {
  background-image: url(/static/media/left-arrow.b40c6889.png) !important;
}

.Form_dayPickerOverlayWrapper__4L75b .DayPicker-NavButton--next {
  background-image: url(/static/media/right-arrow.31afce18.png) !important;
}

.Form_dayPickerOverlayWrapper__4L75b .DayPicker-Day--selected {
  background-color: var(--eden) !important;
  font-weight: normal !important;
}

.Form_dayPickerOverlayWrapper__4L75b
  .DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
  background-color: var(--apple-20) !important;
  color: var(--slate-darken-20) !important;
}

.Form_selectCover__1EHOp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.Form_selectModal__2pfdP {
  background: white;
  margin-top: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 8px;
  max-height: 600px;
  overflow-y: auto;
}

.Form_selectValue__17uwU {
  padding: 8px;
  font-size: 14px;
  color: var(--slate-85);
}

.Form_selectValue__17uwU:hover {
  background: var(--slate-05);
  border-radius: 4px;
  color: var(--slate-darken-20);
  cursor: pointer;
}

.Form_modalOuter__2iNfj {
  position: absolute;
  height: 0;
  z-index: 1001;
}

.Form_label__1I2L5 {
  margin-bottom: 24px;
  display: block;
}

.Form_label__1I2L5:last-of-type {
  margin-bottom: 40px;
}

.Form_labelText__3A-hb {
  margin-bottom: 8px;
  font-weight: var(--semibold);
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-darken-20);
  text-align: left;
}

.Form_linkWithIcon__2EDTl {
  display: flex;
  align-items: center;
}

.Form_linkWithIcon__2EDTl:hover {
  cursor: pointer;
}

.Form_listItemInner__1wCmc {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-darken-20);
  padding-left: 18px;
}

.Form_listItemOuter__1eNYj {
  display: flex;
  padding-bottom: 13px;
  border-width: 0 0 1px 0;
  border-color: var(--slate-15);
  border-style: solid;
  margin-bottom: 13px;
}

.Form_listItemOuter__1eNYj:last-child {
  border: none;
}

.Form_listItemOuter__1eNYj:hover {
  cursor: pointer;
}

.Form_errorText__3rQSL {
  font-size: 12px;
  line-height: 150%;
  color: var(--razz-darken-20);
  margin-top: 4px;
}

.Icons_calendarOuter__2JgDv {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
}

.Icons_edenCalendarHeader__GgShO,
.Icons_oceanCalendarHeader__3PCiU,
.Icons_lemonCalendarHeader__16BM2 {
  padding: 2px 13px;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.Icons_edenCalendarHeader__GgShO {
  background: var(--eden-gradient);
}

.Icons_oceanCalendarHeader__3PCiU {
  background: var(--ocean-gradient);
}

.Icons_lemonCalendarHeader__16BM2 {
  background: var(--lemon-gradient);
  color: var(--slate-darken-20);
}

.Icons_calendarFooter__26Eut {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--slate);
  padding: 3px 11px 6px 11px;
  font-weight: 800;
}

.NewPassword_container__2_Ma7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.NewPassword_passwordMismatch__1XQsw {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.NewPassword_validationBox__2pTKd {
  background: var(--slate-05);
  color: var(--slate-85);
  border-radius: 8px;
  width: 400px;
  max-width: 400px;
  margin-top: 24px;
  align-items: center;
  padding: 16px 24.67px;
}

.NewPassword_validationItem__1vYa- {
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NewPassword_validationItem__1vYa-:last-child {
  padding-bottom: 0px;
}

.NewPassword_validationItem__1vYa- > svg {
  margin-right: 8.67px;
}

.Challenge_logo__1Bfn8 {
  margin-bottom: 32px;
}

.Challenge_headerText__NEwjM {
  margin-bottom: 16px;
  max-width: 560px;
  font-weight: normal;
  text-align: center;
}

.Challenge_container__2rz7U {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-end;
}

.Challenge_incorrectCode__1TtD8 {
  width: 347px;
  background: var(--razz-20);
  border-radius: 4px;
  padding: 12px 26px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.Challenge_infoContainer__2oYBu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Challenge_qrBox__190So {
  width: 200px;
  height: 200px;
  margin-bottom: 48px;
  border: 2px solid var(--slate-15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Challenge_authSuccess__1rp5_ {
  width: 214px;
  height: 24px;
  background: var(--apple-20);
  color: var(--slate-darken-20);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
}

.Sidebar_container__2TZn5,
.Sidebar_spacer__YZnYc {
  padding-left: var(--sidebarPaddingHoriz);
  margin-right: var(--sidebarPaddingHoriz);
}

.Sidebar_container__2TZn5 {
  background-color: var(--slate-05);
  --paddingVert: 40px;
  padding-top: var(--paddingVert);
  padding-bottom: var(--paddingVert);
  height: calc(100vh - 2 * var(--paddingVert));
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  width: var(--sidebarContainerWidth);
  min-width: var(--sidebarContainerWidth);
  max-width: var(--sidebarContainerWidth);
}

.Sidebar_spacer__YZnYc {
  width: var(--sidebarSpacerWidth);
  min-width: var(--sidebarSpacerWidth);
  max-width: var(--sidebarSpacerWidth);
}

.Sidebar_label__1ekpg {
  padding: 14px;
  display: flex;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  color: var(--slate-85);
  margin-bottom: 2px;
}

.Sidebar_label__1ekpg:hover {
  background: var(--slate-15);
}

.Sidebar_label__1ekpg.Sidebar_chosen__3P5bd {
  background: var(--slate-15);
  color: var(--slate-darken-20);
}

.Sidebar_label__1ekpg path {
  fill: var(--slate-85);
}

.Sidebar_label__1ekpg.Sidebar_chosen__3P5bd path {
  fill: var(--slate-darken-20);
}

.Sidebar_title__3Z1FD {
  color: inherit;
}

.Sidebar_refreshButton__2IoJR {
  border: 1px solid var(--slate-35);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 135%;
  padding: 6px 12px;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--slate-85);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sidebar_refreshButton__2IoJR:hover {
  cursor: pointer;
  background: var(--slate-15);
}

.Dropdown_dropdown__37wT5,
.Dropdown_dropdownButton__2Wjx7 {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Dropdown_dropdownButton__2Wjx7 {
  background: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  border-width: 0;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
}

.Dropdown_dropdownButtonActive__ZGMph {
  background: var(--ocean);
}

.Dropdown_dropdownButtonText__1PtyH {
  font-weight: var(--semibold);
  margin-right: 8px;
}

.Dropdown_arrow__PBZhc {
  height: 24px;
  width: 24px;
  margin-left: 8px;
  border-radius: 4px;
}

.Dropdown_arrow__PBZhc:hover,
.Dropdown_arrow__PBZhc.Dropdown_open__3CUqs {
  background: var(--slate-15);
}

.Dropdown_modalOuter__3CCRa {
  position: absolute;
  height: 0px;
  z-index: 1001;
}

.Dropdown_modal__3CLp6 {
  min-width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  vertical-align: center;
  margin-top: 8px;
}

.Dropdown_kicker__1Ymzw {
  height: 20px;
  text-align: center;
  font-weight: bold;
  padding: 8px 12px;
}

.Dropdown_divider__2TBEz {
  margin-top: 8px;
  margin-bottom: 8px;
  height: 1px;
  width: 100%;
  width: calc(100% - 3px);
  border-radius: 1px;
  background: var(--slate-15);
  align-self: center;
}

.Dropdown_item__2kv4A {
  height: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  width: calc(100% - 24px);
}

.Dropdown_item__2kv4A:hover {
  background: var(--slate-05);
  cursor: pointer;
}

.Toast_container__2VNlV {
  border-radius: 100px !important;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  width: auto;
}

.Toast_generic__3jIPx {
  border-radius: 6px;
  color: var(--slate);
  padding: 0 20px;
}

.Toast_generic__3jIPx a {
  color: var(--eden);
  text-decoration: none;
}

.Toast_success__2MU6A {
  background-color: var(--apple-40);
}

.Toast_failure__18fm7 {
  background-color: var(--razz-20);
}

.Toast_iconWrapper__3zF33 svg {
  margin-bottom: -3px;
  margin-right: 8px;
}

.DashboardModal_overlay__2LlhI {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.DashboardModal_content__2BXBp {
  position: absolute;
  width: 640px;
  min-height: 609px;
  padding-top: 64px;
  padding-bottom: 64px;
  left: 50%;
  top: 207px;
  transform: translate(-50%, 0%);
  background: var(--white);
  border-radius: 16px;
  outline: none;
}

.DashboardModal_closeButton__1MP3K {
  position: absolute;
  top: 29px;
  right: 29px;
  cursor: pointer;
}

.DashboardModal_container__1BvBz {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MemberList_header__gI_lw {
  padding-top: 32px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom-width: 1px;
  border-bottom-color: var(--slate-05);
  width: 100%;
  border-bottom-style: solid;
}

.MemberList_container__2BOX_ {
  --margin-left: 24px;
  --padding-right: 53px;
  margin-left: var(--margin-left);
  padding-right: var(--padding-right);
  width: calc(100vw - var(--sidebarSpacerWidth) - var(--margin-left) - var(--padding-right) - 2px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

.MemberList_subheader__sNSYz {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}

.MemberList_pickers__3wxO9 {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 15px;
}

.MemberList_table__1paDG {
  min-width: 100%;
}

.MemberList_thead__2v0Z9 .edenTableCell:first-child {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 100;
}

.MemberList_table__1paDG .edenTableCell:first-child {
  position: sticky !important;
  left: 0;
}

.MemberList_table__1paDG .edenTableCell:nth-child(2) {
  position: sticky !important;
  left: 90px;
}

.MemberList_row__2R9Tq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.MemberList_tableWrapper__1yl6P {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  padding-bottom: 60px;
}

.MemberList_viewDetails__MHiMT {
  cursor: pointer;
  color: var(--eden-darken-10);
}

.MemberList_loader__1SegT {
  height: 80vh;
  width: calc(100vw - var(--sidebarSpacerWidth));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MemberList_unconsentedButton__SGQto {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  border: 1px solid var(--slate-15);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.MemberList_unconsentedButton__SGQto:hover {
  background: var(--slate-05);
}

.MemberList_modalOuter__3Yn7B {
  display: flex;
  justify-content: flex-end;
}

.MemberList_nameSearchField__3S_ST {
  flex-grow: 1;
}

.Orb_orb__1QOTC,
.Orb_orb__1QOTC.Orb_incomplete__1YT_9,
.Orb_orb__1QOTC.Orb_gray__2VPwe {
  height: 8px;
  width: 8px;
  min-width: 8px;
  background: var(--slate-15);
  border-radius: 100px;
  margin-right: 8px;
}

.Orb_orb__1QOTC.Orb_positive__3IeyV,
.Orb_orb__1QOTC.Orb_red__25FtH {
  background: var(--razz);
}

.Orb_orb__1QOTC.Orb_blue__3qIN9 {
  background: var(--ocean);
}

.Orb_orb__1QOTC.Orb_isolate__DD49d,
.Orb_orb__1QOTC.Orb_open__3C9Tk,
.Orb_orb__1QOTC.Orb_inconclusive__2A2X0,
.Orb_orb__1QOTC.Orb_yellow__UtT9N {
  background: var(--lemon);
}

.Orb_orb__1QOTC.Orb_cleared__1-4SL,
.Orb_orb__1QOTC.Orb_negative__1L_xc,
.Orb_orb__1QOTC.Orb_green__s-slF {
  background: var(--eden);
}

.Orb_orb__1QOTC.Orb_pending__CpNrH {
  display: none;
}

.Orb_orb__1QOTC.Orb_hidden__KnhHz {
  visibility: hidden;
  background: red;
}

.Orb_orb__1QOTC,
.Orb_orbContainer__3g6qm {
  display: flex;
  align-items: center;
  margin: 0 8px;
}

.Orb_withDownloadIcon__3P88q {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@keyframes Spinner_spin__3FOgB {
  100% {
    transform: rotate(360deg);
  }
}

.Spinner_spinner__2BPZR {
  animation: Spinner_spin__3FOgB 1s linear infinite;
  height: 56px;
  width: 56px;
}

.DocumentDownloader_button__263v2 {
  display: flex;
  cursor: pointer;
}

.UnconsentedModal_unconsentedModalContent__1LxTF {
  position: absolute;
  width: 75%;
  left: 13%;
  --contentVerticalOffset: 80px;
  top: var(--contentVerticalOffset);
  bottom: var(--contentVerticalOffset);
  background: var(--white);
  border-radius: 16px;
  outline: none;
  padding: 40px;
  overflow-y: scroll;
}

.UnconsentedModal_unconsentedModalOverlay__2h2yf {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.UnconsentedModal_tableWrapper__1DFzE {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  --verticalPadding: 60px;
  padding-bottom: var(--verticalPadding);
}

.UnconsentedModal_table__2OyZI {
  min-width: 100%;
  /* If I try to show where 380px comes from, it does not work:
   * --totalOffset: calc((2 * var(--verticalPadding)) + (3 * var(--contentVerticalOffset) + 100px); */
  height: calc(100vh - 380px);
}

.UnconsentedModal_table__2OyZI .edenTableCell:nth-child(2) {
  border-right: var(--slate-15) 1px solid;
}

.Table_headers__fR8JV {
  align-self: center;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 100;
}

.Table_tableOuter__3YG0z {
  --footerHeight: 58px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--slate-05);
  border-radius: 8px;
  margin-bottom: 32px;
  overflow-y: hidden;
}

.Table_headerCell__3wHxZ {
  padding: 8px;
  display: flex;
  margin: 8px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -moz-fit-content;
  width: fit-content;
  height: 12px;
}

.Table_vaccinationStatus__3bSQH .Table_headerCell__3wHxZ,
.Table_workStatus__mQ_Gw .Table_headerCell__3wHxZ,
.Table_lastTest__MVRBr .Table_headerCell__3wHxZ,
.Table_labResultStatus__2O1Eo .Table_headerCell__3wHxZ {
  margin-left: 24px;
}

.Table_table__rU49i .Table_td__3-oV6.Table_download__144SE {
  padding-left: 8px;
  padding-right: 8px;
}

.Table_thead__1FgUa .edenTableCell {
  background-color: var(--slate-05);
  color: var(--slate-darken-20);
  border-bottom: var(--slate-05) 1px solid;
  opacity: 1;
}

.Table_table__rU49i .Table_td__3-oV6 {
  padding: 16px;
  margin: 0;
  word-wrap: break-word;
  word-break: break-all;
  border-bottom: var(--slate-05) 2px solid;
  background-color: var(--white);
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*
 * Potential two-line cell. Adapts its padding to keep row height the same.
 */
.Table_tbody__1b9Zv .Table_isolateReasons__7nNZD {
  padding: 8px 16px;
}

/*
 * Right border on the last column of column groups.
 */
.Table_tbody__1b9Zv .Table_lastName__1EsPT,
.Table_tbody__1b9Zv .Table_dateOfLastDose__HiaLw,
.Table_tbody__1b9Zv .Table_lastScreenedAt__2266x,
.Table_tbody__1b9Zv .Table_testTime__1kz_1 {
  border-right: var(--slate-15) 1px solid;
}

.Table_table__rU49i .Table_trClickable__k18V0:hover {
  cursor: pointer;
}

.Table_table__rU49i .Table_tr__2uWHe:hover .Table_td__3-oV6 {
  background-color: var(--slate-05);
}

.Table_activeFilter__EYHaK {
  background-color: var(--slate-15);
  border-radius: 4px;
}

.Table_footer__-YDLD {
  background-color: var(--slate-05);
  display: flex;
  color: var(--slate-darken-20);
  justify-content: space-between;
  position: sticky;
  left: 0;
  bottom: 0;
  flex-wrap: wrap;
}

.Table_footer__-YDLD > div {
  padding: 8px 16px;
}

.Table_innerLeftFooter__2wsv3 {
  margin-right: 16px;
}

.Table_innerRightFooter__3Cl4J,
.Table_innerLeftFooter__2wsv3,
.Table_rightFooter__3c62o,
.Table_leftFooter__3Kdsm {
  display: flex;
  align-items: center;
}

.Table_nowrap__1I8bZ {
  clear: both;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

input.Table_pageField__14V12,
input.Table_pageField__14V12:focus {
  width: 24px;
  padding: 8px 8px;
  text-align: center;
}

.Table_scrollableTableBody__3Fqsm {
  width: 100%;
}

.Table_scrollableTable__3HoGy {
  flex-shrink: 1;
  height: calc(100% - var(--footerHeight));
  overflow: scroll;
  width: 100%;
}

.Table_scrollableTableContainer__afzdb {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Table_simpleButton__6zi4s {
  background: white;
  border: 1px solid var(--slate-15);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

.Table_simpleButton__6zi4s:hover {
  cursor: pointer;
  background: var(--slate-05);
  border: 1px solid var(--slate-15);
}

.Table_simpleButton__6zi4s.Table_disabled__3YmWC,
.Table_simpleButton__6zi4s.Table_disabled__3YmWC:hover {
  background: var(--slate-05);
  cursor: default;
  border: 1px solid var(--slate-05);
}

.Tooltip_tooltipButton__33iiL {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 14px;
  width: 14px;
}

/* Changes Tippy default tooltip box */
.tippy-arrow {
  color: var(--slate-darken-20) !important;
}
.tippy-box {
  background-color: var(--slate-darken-20) !important;
  border-radius: 6px;
  width: 236px;
  white-space: normal;
  padding: 8px 4px;
  border: none;
  text-transform: none;
  letter-spacing: normal;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.MemberDashboard_container__FO7uQ {
  display: flex;
  height: 100vh;
  overflow: hidden;

  --sidebarPaddingHoriz: 16px;
  --sidebarContainerWidth: calc(166px + var(--sidebarPaddingHoriz));
  --sidebarSpacerWidth: calc(var(--sidebarContainerWidth) + var(--sidebarPaddingHoriz));
}

.MemberDashboard_noscroll__xdspH {
  overflow: hidden;
}

.Flyout_cover__1IBFA {
  z-index: 1001;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.5;
  position: absolute;
}

.Flyout_container__2PEm2 {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1002;
}

.Flyout_flyout__2LrTi {
  --paddingVert: 36px;
  position: absolute;
  z-index: 1003;
  height: calc(100vh - 2 * var(--paddingVert));
  right: 0;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: var(--paddingVert) 32px;
  min-width: 500px;
  width: 500px;
  overflow-y: scroll;
}

.Flyout_iconParagraph__2wnOH {
  font-size: 14px;
  color: var(--slate-darken-20);
  display: flex;
  padding: 14px 20px;
}

.Flyout_iconParagraph__2wnOH > div,
.Flyout_iconParagraph__2wnOH > svg {
  margin: 3px 8px 0 0;
  flex-shrink: 0;
}

.Flyout_strippedTable__2_fXa {
  border: none;
  width: 100%;
  margin-bottom: 32px;
  margin-left: 8px;
}

.Flyout_strippedTable__2_fXa tr:not(:last-child) div {
  margin-bottom: 8px;
}

.Flyout_strippedTable__2_fXa td:last-child div {
  font-weight: var(--semibold);
  color: var(--slate-darken-20);
}

.Flyout_strippedTable__2_fXa > tr > td:last-child {
  font-weight: var(--semibold);
}

.Flyout_x__2N-Cu:hover {
  cursor: pointer;
}

.Flyout_table__1lWsp {
  width: 100%;
}

.Flyout_tableSelectorContainer__2CSQv {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
}

.Flyout_tableSelect__2fbqo,
.Flyout_tableSelectActive__3xGr2 {
  margin: 0px 8px 16px 0px;
  height: 28px;
  border-radius: 100px;
  border: none;
  outline: none;
  background: var(--white);
}

.Flyout_tableSelect__2fbqo:hover {
  background: var(--slate-05);
}

.Flyout_tableSelectActive__3xGr2 {
  background: var(--slate-darken-20);
}

.Flyout_tableSelectText__1ov0x,
.Flyout_tableSelectTextActive__2zbgm {
  padding: 0px 8px 0px 8px;
  font-weight: var(--bold);
  color: var(--slate-85);
}

.Flyout_tableSelectTextActive__2zbgm {
  color: var(--white);
}

.Tabs_tabsContainer__1oYsc {
  display: flex;
  flex-direction: column;
}

.Tabs_tabs__1X2EC {
  display: flex;
  flex-direction: row;
  padding-left: 8px;
}

.Tabs_tab__1NEDM {
  margin: 0 8px 16px 0;
  height: 28px;
  border-radius: 100px;
  border: none;
  outline: none;
  background: var(--white);
}

.Tabs_tab__1NEDM:hover {
  background: var(--slate-05);
}

.Tabs_tab__1NEDM.Tabs_active__25acE {
  background: var(--slate-darken-20);
}

.Tabs_text__31tUU.Tabs_active__25acE {
  font-weight: var(--bold);
  color: var(--white);
}

.Tabs_text__31tUU {
  padding: 0 8px;
  font-weight: var(--bold);
  color: var(--slate-85);
}

.VisitorDashboard_container__3DADA {
  display: flex;
  height: 100vh;
  overflow: hidden;

  --sidebarPaddingHoriz: 16px;
  --sidebarContainerWidth: calc(166px + var(--sidebarPaddingHoriz));
  --sidebarSpacerWidth: calc(var(--sidebarContainerWidth) + var(--sidebarPaddingHoriz));
}

.VisitorDashboard_noscroll__1UfxI {
  overflow: hidden;
}

.VisitorList_header__eN7T6 {
  padding-top: 32px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom-width: 1px;
  border-bottom-color: var(--slate-05);
  width: 100%;
  border-bottom-style: solid;
}

.VisitorList_container__3AmPd {
  --margin-left: 24px;
  --padding-right: 53px;
  margin-left: var(--margin-left);
  padding-right: var(--padding-right);
  width: calc(100vw - var(--sidebarSpacerWidth) - var(--margin-left) - var(--padding-right) - 2px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

.VisitorList_subheader__3iigU {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
}

.VisitorList_pickers__4597A {
  display: flex;
  flex-direction: row;
}

.VisitorList_table__2ZWUq {
  min-width: 100%;
}

.VisitorList_table__2ZWUq .edenTableCell:nth-child(2) {
  border-right: var(--slate-15) 1px solid;
}

.VisitorList_leftSubHeader__2bklm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.VisitorList_tableWrapper__fedz3 {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  padding-bottom: 60px;
}

.VisitorList_viewDetails__2pi9s {
  cursor: pointer;
  color: var(--eden-darken-10);
}

.VisitorList_loader__129E7 {
  height: 80vh;
  width: calc(100vw - var(--sidebarSpacerWidth));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.VisitorList_screenerLinkModalContainer__6vd_J {
  padding: 16px;
  width: 500px;
}

.VisitorList_screenerLinkSection__1XC8P {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.VisitorList_screenerLinkCopyButton__3YQiz {
  cursor: pointer;
  margin-left: 8px;
  width: 114px;
  color: var(--white);
  background: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  border-width: 0;
  border-radius: 4px;
  font-weight: var(--semibold);
}

.VisitorList_screenerLinkCopyButton__3YQiz:active {
  background: var(--ocean);
}

.VisitorList_screenerLinkInfo__1-pAT {
  display: flex;
  flex-direction: row;
  padding: 12px 16px 12px 16px;
  margin-top: 24px;
  background: var(--ocean-20);
  border-radius: 4px;
}

.VisitorList_screenerLinkInfoIcon__3pP4z {
  margin: 8px 4px 0 0;
  flex-shrink: 0;
}

body {
  overscroll-behavior: none;
}

.Login_container__1pEaC {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-end;
}

.Login_bgContainer__36iML {
  background: linear-gradient(144.58deg, #14b87c 0%, #a8e6c2 100%);
  width: 100%;
  height: 100%;
  z-index: 0;
  position: fixed;
}

.Login_bg__2sXMl {
  background-image: url(/static/media/loginBG.b9a5f74f.svg);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.Login_panel__1IQ8F {
  --panel-padding-width: 120px;
  --padding-top: 120px;
  --padding-bottom: 64px;
  position: relative;
  z-index: 1;
  min-width: 400px;
  max-width: calc(400px + 2 * var(--panel-padding-width));
  width: calc(50vw - 2 * var(--panel-padding-width));
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  background: white;
  padding: var(--padding-top) var(--panel-padding-width) var(--padding-bottom);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Login_logo__21i3H {
  margin-left: -16px;
  margin-bottom: 40px;
}

.Login_footer__2owB1 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 64px;
}

.Login_form__ndDvp {
  width: 400px;
}

.Login_error__3PGSc {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.ForgotPasswordModal_overlay__13v-y {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.ForgotPasswordModal_content__2xOA5 {
  position: absolute;
  width: 640px;
  height: 400px;
  left: 50%;
  top: 207px;
  transform: translate(-50%, 0%);
  background: var(--white);
  border-radius: 16px;
  outline: none;
}

.ForgotPasswordModal_closeButton__eW_Fb {
  position: absolute;
  top: 29px;
  right: 29px;
  cursor: pointer;
}

.ForgotPasswordModal_container__3UHKP {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ForgotPasswordModal_formInput__12_cv {
  width: 400px;
}

.ForgotPasswordModal_forgotHeader__2HbyO {
  margin-top: 80px;
  margin-bottom: 48px;
  text-align: center;
}

.ForgotPasswordModal_checkIcon__pd7ZG {
  margin-top: 56px;
}

.ForgotPasswordModal_sentHeader__vrph7 {
  margin-top: 32px;
  text-align: center;
}

.ForgotPasswordModal_sentText__2JiGa {
  width: 528px;
  margin-top: 16px;
  text-align: center;
}

.ForgotPasswordModal_sentEmail__3qEc1 {
  font-weight: var(--semibold);
  color: var(--slate-darken-20);
}

.ForgotPasswordModal_resendLink__36O2- {
  margin-top: 36px;
  color: var(--eden);
  cursor: pointer;
  text-align: center;
}

.ForgotPasswordModal_divider__PNRmT {
  width: 528px;
  height: 2px;
  margin-top: 44px;
  background: var(--slate-05);
  border-radius: 1px;
}

.ForgotPasswordModal_noCanDo___kyBh {
  margin-top: 24px;
  text-align: center;
}

body {
  overscroll-behavior: none;
}

.ChallengeScreen_container__1U3uF {
  width: 100vw;
  height: calc(100vh - 56px);
  padding-top: 56px;
}

.BeginScreenerPage_container__PmYI1 {
  width: 100%;
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  text-align: center;
}

.OnsiteDetailsPage_container__bd5z6 {
  width: 100%;
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  padding-top: 48px;
}

.OnsiteDetailsPage_innerContainer__1C7Jf {
  width: 350px;
}

.OnsiteDetailsPage_spinner__2iRcd {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
}

.BackButton_backButton__3mgpg {
  position: absolute;
  display: flex;
  align-items: center;
}

.BackButton_backButton__3mgpg:hover {
  cursor: pointer;
}

.Options_container__1fg5D {
  border-radius: 4px;
  background-color: white;
  display: flex;
  width: 100%;
}

.Options_cell__1EHYU {
  /* This color was custom in design, asking Kim */
  border: 2px solid var(--slate-15);
  width: 200px;
  text-align: center;
  padding: 8px 0;
}

.Options_cell__1EHYU:hover {
  cursor: pointer;
}

.Options_cell__1EHYU.Options_leftCell__3XeAq {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
}

.Options_cell__1EHYU.Options_rightCell__3nbjv {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Options_cell__1EHYU.Options_selected__1Cim5 {
  border: 2px solid var(--eden);
  background-color: var(--apple-20);
}

.Options_cell__1EHYU.Options_leftOfSelected__1R-h- {
  border-left: none;
}

.ScreenerPage_container__3Wiy1 {
  width: 100%;
  max-width: 560px;
  margin: 0 auto 80px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.ScreenerPage_innerContainer__2DdDl {
  width: 400px;
  max-width: calc(100vw - 2 * var(--horiz-padding) - 16px);
}

.ScreenerPage_questionContainer__3N10X {
  margin-bottom: 40px;
}

.ScreenerPage_error__39Sca {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.OptionsModal_modalOverlay__rVu4u {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OptionsModal_modalContent__3UQGD {
  --modal-vert-padding: 66px;
  --modal-horiz-padding: 65px;
  max-width: calc(543px - 2 * var(--modal-horiz-padding));
  background: var(--white);
  border-radius: 16px;
  max-height: calc(100vh - 80px - 2 * var(--modal-vert-padding));
  overflow-y: hidden;
  outline: none;
  padding: var(--modal-vert-padding) var(--modal-horiz-padding);
  text-align: center;
  position: static;
}

.OptionsModal_options__1DQx9 {
  max-height: 40vh;
  overflow-y: scroll;
  border-radius: 6px;
  border: var(--slate-15) 1px solid;
  padding: 26px 18px;
  margin: 0 0 28px 0;
}

.OptionsModal_form__GA5Cg {
  width: 100%;
  margin: auto;
}

.OptionsModal_xicon__1cp5V {
  position: relative;
  right: -22px;
  top: -32px;
  width: -moz-fit-content;
  width: fit-content;
  height: 0;
  float: right;
}

.OptionsModal_xicon__1cp5V:hover {
  cursor: pointer;
}

.OptionsModal_answerBubble__1Oyq4 {
  padding: 8px 16px;
  background-color: var(--apple-20);
  border-radius: 1000px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 8px;
}

.OptionsModal_answerContainer__1r9el {
  display: flex;
  margin-top: 4px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.OptionsModal_bubbleX__1MCiP:hover {
  cursor: pointer;
}

.VisitorReceiptPage_header__2H67Q {
  text-align: center;
  margin-bottom: 46px;
}

.VisitorReceiptPage_card__2A8XR {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 56px;
}

.VisitorReceiptPage_card__2A8XR.VisitorReceiptPage_approved__18Isg {
  background-color: var(--eden);
}

.VisitorReceiptPage_card__2A8XR.VisitorReceiptPage_isolate__2J5L9 {
  background-color: var(--lemon-60);
}

.VisitorReceiptPage_card__2A8XR.VisitorReceiptPage_approved__18Isg .VisitorReceiptPage_text__3Urmx {
  color: white;
}

.VisitorReceiptPage_page__2uwnd {
  width: 350px;
  margin: auto;
}

.VisitorReceiptPage_pageOuter__2XbGJ {
  width: 350px;
  margin: auto;
  padding-top: 36px;
}

.VisitorReceiptPage_cardHeader__1WgnF {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -13px;
}

.VisitorReceiptPage_calendar__1JQ06 {
  position: relative;
  top: -26px;
}

.VisitorReceiptPage_innerCard__1oYuR {
  margin: 16px 0;
  padding: 24px;
  border-radius: 8px;
}

.VisitorReceiptPage_innerCard__1oYuR .VisitorReceiptPage_text__3Urmx,
.VisitorReceiptPage_innerCard__1oYuR.VisitorReceiptPage_approved__18Isg .VisitorReceiptPage_text__3Urmx {
  color: var(--slate);
}

.VisitorReceiptPage_innerCard__1oYuR.VisitorReceiptPage_approved__18Isg {
  background-color: var(--apple-40);
}

.VisitorReceiptPage_innerCard__1oYuR.VisitorReceiptPage_isolate__2J5L9 {
  background-color: var(--lemon-20);
}

.VisitorReceiptPage_screenTime__Jxyk7 {
  padding-top: 16px;
  border-width: 1px 0 0 0;
  border-style: solid;
  font-size: 14px;
}

.VisitorReceiptPage_approved__18Isg > .VisitorReceiptPage_screenTime__Jxyk7 {
  border-color: var(--eden);
}

.VisitorReceiptPage_isolate__2J5L9 > .VisitorReceiptPage_screenTime__Jxyk7 {
  border-color: var(--lemon-60);
}

.VisitorReceiptPage_visitorTag__3MKwQ,
.VisitorReceiptPage_hostTag__3RQEs {
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  border-radius: 100px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 6px;
}

.VisitorReceiptPage_visitorTag__3MKwQ {
  background: var(--ocean);
}

.VisitorReceiptPage_hostTag__3RQEs {
  background: var(--eden);
}

.VisitorReceiptPage_section__3gj-s {
  padding-bottom: 24px;
}

.VisitorReceiptPage_sectionTopRow__1ABlL {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.VisitorReceiptPage_approvedBubble__1XeUq,
.VisitorReceiptPage_approvedBubbleMobile__3_bLD,
.VisitorReceiptPage_isolateBubble__3zUVw,
.VisitorReceiptPage_isolateBubbleMobile__2I3b5 {
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 48px;
  flex-grow: 1;
  margin-right: 24px;
  margin-top: 5px;
}

.VisitorReceiptPage_approvedBubbleMobile__3_bLD,
.VisitorReceiptPage_isolateBubbleMobile__2I3b5 {
  flex-grow: 0;
  margin-bottom: 16px;
}

.VisitorReceiptPage_approvedBubble__1XeUq,
.VisitorReceiptPage_approvedBubbleMobile__3_bLD {
  background-color: var(--apple-40);
}

.VisitorReceiptPage_isolateBubble__3zUVw,
.VisitorReceiptPage_isolateBubbleMobile__2I3b5 {
  background-color: var(--lemon-60);
}

.VisitorReceiptPage_advice__2K0RO,
.VisitorReceiptPage_adviceMobile__3Pg4o {
  display: flex;
  margin-bottom: 24px;
}

.VisitorReceiptPage_adviceMobile__3Pg4o {
  flex-wrap: wrap;
}

.VisitorReceiptPage_adviceHeader__3Jj4Z {
  font-weight: 600;
}

.VisitorReceiptPage_adviceSection__1_lLd {
  padding-bottom: 56px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--slate-15);
  margin-bottom: 56px;
}

.VisitorReceiptPage_questionAnswerContainer__2JuAt,
.VisitorReceiptPage_questionAnswerContainerMobile__3r9Pq {
  display: flex;
  width: 350px;
  flex-direction: row;
  padding-bottom: 16px;
}

.VisitorReceiptPage_questionAnswerContainerMobile__3r9Pq {
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.VisitorReceiptPage_question__3p8ps,
.VisitorReceiptPage_subQuestion__1PfEe {
  padding-right: 40px;
}

.VisitorReceiptPage_answer__31NuJ {
  font-weight: 600;
}

.VisitorReceiptPage_subQuestion__1PfEe {
  padding-top: 16px;
}

.VisitorForm_container__17IRC,
.VisitorForm_mobileContainer__2AYAW {
  --horiz-padding: 91px;
  --vert-padding: 16px;
  padding: var(--vert-padding) var(--horiz-padding);
  width: calc(100vw - 2 * var(--horiz-padding) - 16px);
  min-height: calc(100vh - 2 * var(--vert-padding));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.VisitorForm_mobileContainer__2AYAW {
  --horiz-padding: 16px;
  margin-left: 8px;
}

.VisitorForm_header__2-pjy,
.VisitorForm_headerMobile__KU8b4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.VisitorForm_headerMobile__KU8b4 {
  align-items: flex-start;
}

.VisitorForm_header__2-pjy div:last-child,
.VisitorForm_headerMobile__KU8b4 div:last-child {
  border-width: 0 0 0 2px;
  border-style: solid;
  border-color: var(--slate-15);
  padding-left: 12px;
  margin-left: 4px;
}

.VisitorForm_footer__zA7JR,
.VisitorForm_mobileFooter__3NEw9 {
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.VisitorForm_footer__zA7JR {
  justify-content: space-between;
}

.VisitorForm_mobileFooter__3NEw9 {
  justify-content: center;
}

.VisitorForm_footerLinks__3JsvU {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.VaccinePage_container__3TzDt {
  width: 100%;
  max-width: 560px;
  margin: 0 auto 80px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.VaccinePage_innerContainer__2OT0C {
  width: 400px;
  max-width: calc(100vw - 2 * var(--horiz-padding) - 16px);
}

.VaccinePage_questionContainer__1lrOJ {
  margin-bottom: 40px;
}

.VaccinePage_error__2IfJW {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

:root {
  --apple: #6ed69a;
  --apple-10: #f0fbf5;
  --apple-20: #e2f7eb;
  --apple-40: #c5efd7;

  --black: #2d4147;

  --white: #ffffff;

  --eden: #14b87c;
  --eden-darken-10: #12a670;

  --lemon: #ffcc25;
  --lemon-20: #fff5d3;
  --lemon-40: #ffeba8;
  --lemon-60: #ffe07c;

  --ocean: #0391f4;
  --ocean-20: #cde9fd;
  --ocean-40: #9ad3fb;

  --razz: #f8506f;
  --razz-10: #feedf1;
  --razz-20: #fedce2;
  --razz-darken-20: #b42641;

  --slate: #3a545b;
  --slate-05: #f5f6f7;
  --slate-15: #e1e5e6;
  --slate-35: #bac3c6;
  --slate-45: #a6b2b5;
  --slate-55: #93a1a5;
  --slate-85: #586e74;
  --slate-darken-20: #2e4349;

  --eden-gradient: linear-gradient(173.12deg, #14b87c 0%, #a8e6c2 100%);
  --ocean-gradient: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  --lemon-gradient: linear-gradient(135deg, #ffcc25 0%, #ffeba8 100%);

  --semibold: 600;
  --bold: 800;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  color: var(--slate-35);
}

/* overriding semantic ui */
.styles_ui__ARHAi.styles_menu__2VBpp,
button {
  font-family: 'Open Sans', sans-serif !important;
}

th {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

