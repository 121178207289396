.unconsentedModalContent {
  position: absolute;
  width: 75%;
  left: 13%;
  --contentVerticalOffset: 80px;
  top: var(--contentVerticalOffset);
  bottom: var(--contentVerticalOffset);
  background: var(--white);
  border-radius: 16px;
  outline: none;
  padding: 40px;
  overflow-y: scroll;
}

.unconsentedModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.tableWrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  --verticalPadding: 60px;
  padding-bottom: var(--verticalPadding);
}

.table {
  min-width: 100%;
  /* If I try to show where 380px comes from, it does not work:
   * --totalOffset: calc((2 * var(--verticalPadding)) + (3 * var(--contentVerticalOffset) + 100px); */
  height: calc(100vh - 380px);
}

.table :global(.edenTableCell):nth-child(2) {
  border-right: var(--slate-15) 1px solid;
}
