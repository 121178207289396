.tooltipButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 14px;
  width: 14px;
}

/* Changes Tippy default tooltip box */
:global(.tippy-arrow) {
  color: var(--slate-darken-20) !important;
}
:global(.tippy-box) {
  background-color: var(--slate-darken-20) !important;
  border-radius: 6px;
  width: 236px;
  white-space: normal;
  padding: 8px 4px;
  border: none;
  text-transform: none;
  letter-spacing: normal;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
