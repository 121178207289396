.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.content {
  position: absolute;
  width: 640px;
  min-height: 609px;
  padding-top: 64px;
  padding-bottom: 64px;
  left: 50%;
  top: 207px;
  transform: translate(-50%, 0%);
  background: var(--white);
  border-radius: 16px;
  outline: none;
}

.closeButton {
  position: absolute;
  top: 29px;
  right: 29px;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
