.header {
  padding-top: 32px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom-width: 1px;
  border-bottom-color: var(--slate-05);
  width: 100%;
  border-bottom-style: solid;
}

.container {
  --margin-left: 24px;
  --padding-right: 53px;
  margin-left: var(--margin-left);
  padding-right: var(--padding-right);
  width: calc(100vw - var(--sidebarSpacerWidth) - var(--margin-left) - var(--padding-right) - 2px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

.subheader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
}

.pickers {
  display: flex;
  flex-direction: row;
}

.table {
  min-width: 100%;
}

.table :global(.edenTableCell):nth-child(2) {
  border-right: var(--slate-15) 1px solid;
}

.leftSubHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableWrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  padding-bottom: 60px;
}

.viewDetails {
  cursor: pointer;
  color: var(--eden-darken-10);
}

.loader {
  height: 80vh;
  width: calc(100vw - var(--sidebarSpacerWidth));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screenerLinkModalContainer {
  padding: 16px;
  width: 500px;
}

.screenerLinkSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.screenerLinkCopyButton {
  cursor: pointer;
  margin-left: 8px;
  width: 114px;
  color: var(--white);
  background: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  border-width: 0;
  border-radius: 4px;
  font-weight: var(--semibold);
}

.screenerLinkCopyButton:active {
  background: var(--ocean);
}

.screenerLinkInfo {
  display: flex;
  flex-direction: row;
  padding: 12px 16px 12px 16px;
  margin-top: 24px;
  background: var(--ocean-20);
  border-radius: 4px;
}

.screenerLinkInfoIcon {
  margin: 8px 4px 0 0;
  flex-shrink: 0;
}
