.calendarOuter {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: fit-content;
  background-color: white;
}

.edenCalendarHeader,
.oceanCalendarHeader,
.lemonCalendarHeader {
  padding: 2px 13px;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.edenCalendarHeader {
  background: var(--eden-gradient);
}

.oceanCalendarHeader {
  background: var(--ocean-gradient);
}

.lemonCalendarHeader {
  background: var(--lemon-gradient);
  color: var(--slate-darken-20);
}

.calendarFooter {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--slate);
  padding: 3px 11px 6px 11px;
  font-weight: 800;
}
