.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.passwordMismatch {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.validationBox {
  background: var(--slate-05);
  color: var(--slate-85);
  border-radius: 8px;
  width: 400px;
  max-width: 400px;
  margin-top: 24px;
  align-items: center;
  padding: 16px 24.67px;
}

.validationItem {
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.validationItem:last-child {
  padding-bottom: 0px;
}

.validationItem > svg {
  margin-right: 8.67px;
}
