.header {
  text-align: center;
  margin-bottom: 46px;
}

.card {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 56px;
}

.card.approved {
  background-color: var(--eden);
}

.card.isolate {
  background-color: var(--lemon-60);
}

.card.approved .text {
  color: white;
}

.page {
  width: 350px;
  margin: auto;
}

.pageOuter {
  width: 350px;
  margin: auto;
  padding-top: 36px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -13px;
}

.calendar {
  position: relative;
  top: -26px;
}

.innerCard {
  margin: 16px 0;
  padding: 24px;
  border-radius: 8px;
}

.innerCard .text,
.innerCard.approved .text {
  color: var(--slate);
}

.innerCard.approved {
  background-color: var(--apple-40);
}

.innerCard.isolate {
  background-color: var(--lemon-20);
}

.screenTime {
  padding-top: 16px;
  border-width: 1px 0 0 0;
  border-style: solid;
  font-size: 14px;
}

.approved > .screenTime {
  border-color: var(--eden);
}

.isolate > .screenTime {
  border-color: var(--lemon-60);
}

.visitorTag,
.hostTag {
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;
  padding: 2px 6px;
}

.visitorTag {
  background: var(--ocean);
}

.hostTag {
  background: var(--eden);
}

.section {
  padding-bottom: 24px;
}

.sectionTopRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.approvedBubble,
.approvedBubbleMobile,
.isolateBubble,
.isolateBubbleMobile {
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 48px;
  flex-grow: 1;
  margin-right: 24px;
  margin-top: 5px;
}

.approvedBubbleMobile,
.isolateBubbleMobile {
  flex-grow: 0;
  margin-bottom: 16px;
}

.approvedBubble,
.approvedBubbleMobile {
  background-color: var(--apple-40);
}

.isolateBubble,
.isolateBubbleMobile {
  background-color: var(--lemon-60);
}

.advice,
.adviceMobile {
  display: flex;
  margin-bottom: 24px;
}

.adviceMobile {
  flex-wrap: wrap;
}

.adviceHeader {
  font-weight: 600;
}

.adviceSection {
  padding-bottom: 56px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--slate-15);
  margin-bottom: 56px;
}

.questionAnswerContainer,
.questionAnswerContainerMobile {
  display: flex;
  width: 350px;
  flex-direction: row;
  padding-bottom: 16px;
}

.questionAnswerContainerMobile {
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.question,
.subQuestion {
  padding-right: 40px;
}

.answer {
  font-weight: 600;
}

.subQuestion {
  padding-top: 16px;
}
