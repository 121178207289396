.container {
  width: 100%;
  max-width: 560px;
  margin: 0 auto 80px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.innerContainer {
  width: 400px;
  max-width: calc(100vw - 2 * var(--horiz-padding) - 16px);
}

.questionContainer {
  margin-bottom: 40px;
}

.error {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
