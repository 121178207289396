.orb,
.orb.incomplete,
.orb.gray {
  height: 8px;
  width: 8px;
  min-width: 8px;
  background: var(--slate-15);
  border-radius: 100px;
  margin-right: 8px;
}

.orb.positive,
.orb.red {
  background: var(--razz);
}

.orb.blue {
  background: var(--ocean);
}

.orb.isolate,
.orb.open,
.orb.inconclusive,
.orb.yellow {
  background: var(--lemon);
}

.orb.cleared,
.orb.negative,
.orb.green {
  background: var(--eden);
}

.orb.pending {
  display: none;
}

.orb.hidden {
  visibility: hidden;
  background: red;
}

.orb,
.orbContainer {
  display: flex;
  align-items: center;
  margin: 0 8px;
}

.withDownloadIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
