@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700&display=swap');

:root {
  --apple: #6ed69a;
  --apple-10: #f0fbf5;
  --apple-20: #e2f7eb;
  --apple-40: #c5efd7;

  --black: #2d4147;

  --white: #ffffff;

  --eden: #14b87c;
  --eden-darken-10: #12a670;

  --lemon: #ffcc25;
  --lemon-20: #fff5d3;
  --lemon-40: #ffeba8;
  --lemon-60: #ffe07c;

  --ocean: #0391f4;
  --ocean-20: #cde9fd;
  --ocean-40: #9ad3fb;

  --razz: #f8506f;
  --razz-10: #feedf1;
  --razz-20: #fedce2;
  --razz-darken-20: #b42641;

  --slate: #3a545b;
  --slate-05: #f5f6f7;
  --slate-15: #e1e5e6;
  --slate-35: #bac3c6;
  --slate-45: #a6b2b5;
  --slate-55: #93a1a5;
  --slate-85: #586e74;
  --slate-darken-20: #2e4349;

  --eden-gradient: linear-gradient(173.12deg, #14b87c 0%, #a8e6c2 100%);
  --ocean-gradient: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  --lemon-gradient: linear-gradient(135deg, #ffcc25 0%, #ffeba8 100%);

  --semibold: 600;
  --bold: 800;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  color: var(--slate-35);
}

/* overriding semantic ui */
.ui.menu,
button {
  font-family: 'Open Sans', sans-serif !important;
}

th {
  padding: 0;
}
