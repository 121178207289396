.tabsContainer {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  flex-direction: row;
  padding-left: 8px;
}

.tab {
  margin: 0 8px 16px 0;
  height: 28px;
  border-radius: 100px;
  border: none;
  outline: none;
  background: var(--white);
}

.tab:hover {
  background: var(--slate-05);
}

.tab.active {
  background: var(--slate-darken-20);
}

.text.active {
  font-weight: var(--bold);
  color: var(--white);
}

.text {
  padding: 0 8px;
  font-weight: var(--bold);
  color: var(--slate-85);
}
