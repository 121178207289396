.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.content {
  position: absolute;
  width: 640px;
  height: 400px;
  left: 50%;
  top: 207px;
  transform: translate(-50%, 0%);
  background: var(--white);
  border-radius: 16px;
  outline: none;
}

.closeButton {
  position: absolute;
  top: 29px;
  right: 29px;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInput {
  width: 400px;
}

.forgotHeader {
  margin-top: 80px;
  margin-bottom: 48px;
  text-align: center;
}

.checkIcon {
  margin-top: 56px;
}

.sentHeader {
  margin-top: 32px;
  text-align: center;
}

.sentText {
  width: 528px;
  margin-top: 16px;
  text-align: center;
}

.sentEmail {
  font-weight: var(--semibold);
  color: var(--slate-darken-20);
}

.resendLink {
  margin-top: 36px;
  color: var(--eden);
  cursor: pointer;
  text-align: center;
}

.divider {
  width: 528px;
  height: 2px;
  margin-top: 44px;
  background: var(--slate-05);
  border-radius: 1px;
}

.noCanDo {
  margin-top: 24px;
  text-align: center;
}
