.container {
  width: 100%;
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  text-align: center;
}
