.container {
  width: 100%;
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  padding-top: 48px;
}

.innerContainer {
  width: 350px;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
}
