.container,
.mobileContainer {
  --horiz-padding: 91px;
  --vert-padding: 16px;
  padding: var(--vert-padding) var(--horiz-padding);
  width: calc(100vw - 2 * var(--horiz-padding) - 16px);
  min-height: calc(100vh - 2 * var(--vert-padding));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileContainer {
  --horiz-padding: 16px;
  margin-left: 8px;
}

.header,
.headerMobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headerMobile {
  align-items: flex-start;
}

.header div:last-child,
.headerMobile div:last-child {
  border-width: 0 0 0 2px;
  border-style: solid;
  border-color: var(--slate-15);
  padding-left: 12px;
  margin-left: 4px;
}

.footer,
.mobileFooter {
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer {
  justify-content: space-between;
}

.mobileFooter {
  justify-content: center;
}

.footerLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
