.container {
  display: flex;
  height: 100vh;
  overflow: hidden;

  --sidebarPaddingHoriz: 16px;
  --sidebarContainerWidth: calc(166px + var(--sidebarPaddingHoriz));
  --sidebarSpacerWidth: calc(var(--sidebarContainerWidth) + var(--sidebarPaddingHoriz));
}

.noscroll {
  overflow: hidden;
}
