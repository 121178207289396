@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.field,
.select,
.fieldContainer > input,
.fieldContainer > input:active,
.fieldContainer > input:focus {
  --padding-width: 12px;
  --icon-width: 16px;
  --border-width: 1px;
  margin: 0;
  border: var(--border-width) solid var(--slate-15);
  border-radius: 4px;
  color: var(--slate-darken-20);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
  line-height: 20px;
  padding: 10px calc(var(--icon-width) + var(--padding-width)) 10px var(--padding-width);
  width: calc(100% - 2 * var(--padding-width) - 2 * var(--border-width) - var(--icon-width));
  outline: none;
}

.field:focus,
.field:active {
  border: 1px solid var(--apple);
}

.fieldContainer > input,
.select,
.select:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../assets/svg/chevronDown.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.select.arrowless,
.select.arrowless:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  padding: 8px 16px;
  width: 100%;
}

.fieldContainer > input:hover,
.select:hover {
  cursor: pointer;
  background-color: var(--slate-05);
}

.fieldContainer.fieldContainerOpen > input,
.fieldContainer.fieldContainerOpen > input:hover {
  background-color: var(--slate-15);
}

.field.error {
  border: 1px solid var(--razz);
  color: var(--razz-darken-20);
}

.field::placeholder {
  color: var(--slate-45);
}

.fieldWithIcon {
  display: flex;
  align-items: center;
  position: relative;
}

.fieldWithIcon input {
  padding-left: 30px;
  box-sizing: border-box;
  width: 100%;
}

.fieldWithIcon svg {
  position: absolute;
  margin-left: 10px;
}

.primaryButtonLoading,
.primaryButtonLoading:active,
.primaryButtonLoading:focus,
.primaryButton,
.primaryButton:active,
.primaryButton:focus {
  font-family: 'Open Sans', sans-serif !important;
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: var(--semibold);
  font-size: 16px;
  line-height: 135%;
  color: white;
  height: 47px;
  width: 100%;
  max-width: 400px;
}

.primaryButton {
  background: var(--eden-gradient);
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
}

.primaryButton:hover {
  cursor: pointer;
}

.primaryButton:disabled {
  background: var(--slate-05);
  color: var(--slate-55);
  cursor: default;
}

.primaryButtonLoading {
  background: var(--eden);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.primaryButtonLoading .inner {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.dayPickerOverlayWrapper {
  display: flex;
  justify-content: flex-end;
}

.dayPickerOverlay {
  position: fixed;
  background: white;
  margin-top: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: var(--slate-darken-20);
  z-index: 1001;
}

.dayPickerOverlayWrapper :global(.DayPicker-Day) {
  width: 24px;
  height: 24px;
}

.dayPickerOverlayWrapper :global(.DayPicker-Caption) {
  color: var(--eden-darken-10);
}

.dayPickerOverlayWrapper :global(.DayPicker-Day--today) {
  color: var(--eden);
}

.dayPickerOverlayWrapper :global(.DayPicker-NavButton--prev) {
  background-image: url(../../assets/img/left-arrow.png) !important;
}

.dayPickerOverlayWrapper :global(.DayPicker-NavButton--next) {
  background-image: url(../../assets/img/right-arrow.png) !important;
}

.dayPickerOverlayWrapper :global(.DayPicker-Day--selected) {
  background-color: var(--eden) !important;
  font-weight: normal !important;
}

.dayPickerOverlayWrapper
  :global(.DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover) {
  background-color: var(--apple-20) !important;
  color: var(--slate-darken-20) !important;
}

.selectCover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.selectModal {
  background: white;
  margin-top: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 8px;
  max-height: 600px;
  overflow-y: auto;
}

.selectValue {
  padding: 8px;
  font-size: 14px;
  color: var(--slate-85);
}

.selectValue:hover {
  background: var(--slate-05);
  border-radius: 4px;
  color: var(--slate-darken-20);
  cursor: pointer;
}

.modalOuter {
  position: absolute;
  height: 0;
  z-index: 1001;
}

.label {
  margin-bottom: 24px;
  display: block;
}

.label:last-of-type {
  margin-bottom: 40px;
}

.labelText {
  margin-bottom: 8px;
  font-weight: var(--semibold);
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-darken-20);
  text-align: left;
}

.linkWithIcon {
  display: flex;
  align-items: center;
}

.linkWithIcon:hover {
  cursor: pointer;
}

.listItemInner {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-darken-20);
  padding-left: 18px;
}

.listItemOuter {
  display: flex;
  padding-bottom: 13px;
  border-width: 0 0 1px 0;
  border-color: var(--slate-15);
  border-style: solid;
  margin-bottom: 13px;
}

.listItemOuter:last-child {
  border: none;
}

.listItemOuter:hover {
  cursor: pointer;
}

.errorText {
  font-size: 12px;
  line-height: 150%;
  color: var(--razz-darken-20);
  margin-top: 4px;
}
