.header {
  padding-top: 32px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom-width: 1px;
  border-bottom-color: var(--slate-05);
  width: 100%;
  border-bottom-style: solid;
}

.container {
  --margin-left: 24px;
  --padding-right: 53px;
  margin-left: var(--margin-left);
  padding-right: var(--padding-right);
  width: calc(100vw - var(--sidebarSpacerWidth) - var(--margin-left) - var(--padding-right) - 2px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

.subheader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}

.pickers {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
}

.table {
  min-width: 100%;
}

.thead :global(.edenTableCell):first-child {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 100;
}

.table :global(.edenTableCell):first-child {
  position: sticky !important;
  left: 0;
}

.table :global(.edenTableCell):nth-child(2) {
  position: sticky !important;
  left: 90px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tableWrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  padding-bottom: 60px;
}

.viewDetails {
  cursor: pointer;
  color: var(--eden-darken-10);
}

.loader {
  height: 80vh;
  width: calc(100vw - var(--sidebarSpacerWidth));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unconsentedButton {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  border: 1px solid var(--slate-15);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.unconsentedButton:hover {
  background: var(--slate-05);
}

.modalOuter {
  display: flex;
  justify-content: flex-end;
}

.nameSearchField {
  flex-grow: 1;
}
