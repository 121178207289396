.headers {
  align-self: center;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 100;
}

.tableOuter {
  --footerHeight: 58px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--slate-05);
  border-radius: 8px;
  margin-bottom: 32px;
  overflow-y: hidden;
}

.headerCell {
  padding: 8px;
  display: flex;
  margin: 8px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  height: 12px;
}

.vaccinationStatus .headerCell,
.workStatus .headerCell,
.lastTest .headerCell,
.labResultStatus .headerCell {
  margin-left: 24px;
}

.table .td.download {
  padding-left: 8px;
  padding-right: 8px;
}

.thead :global(.edenTableCell) {
  background-color: var(--slate-05);
  color: var(--slate-darken-20);
  border-bottom: var(--slate-05) 1px solid;
  opacity: 1;
}

.table .td {
  padding: 16px;
  margin: 0;
  word-wrap: break-word;
  word-break: break-all;
  border-bottom: var(--slate-05) 2px solid;
  background-color: var(--white);
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*
 * Potential two-line cell. Adapts its padding to keep row height the same.
 */
.tbody .isolateReasons {
  padding: 8px 16px;
}

/*
 * Right border on the last column of column groups.
 */
.tbody .lastName,
.tbody .dateOfLastDose,
.tbody .lastScreenedAt,
.tbody .testTime {
  border-right: var(--slate-15) 1px solid;
}

.table .trClickable:hover {
  cursor: pointer;
}

.table .tr:hover .td {
  background-color: var(--slate-05);
}

.activeFilter {
  background-color: var(--slate-15);
  border-radius: 4px;
}

.footer {
  background-color: var(--slate-05);
  display: flex;
  color: var(--slate-darken-20);
  justify-content: space-between;
  position: sticky;
  left: 0;
  bottom: 0;
  flex-wrap: wrap;
}

.footer > div {
  padding: 8px 16px;
}

.innerLeftFooter {
  margin-right: 16px;
}

.innerRightFooter,
.innerLeftFooter,
.rightFooter,
.leftFooter {
  display: flex;
  align-items: center;
}

.nowrap {
  clear: both;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input.pageField,
input.pageField:focus {
  width: 24px;
  padding: 8px 8px;
  text-align: center;
}

.scrollableTableBody {
  width: 100%;
}

.scrollableTable {
  flex-shrink: 1;
  height: calc(100% - var(--footerHeight));
  overflow: scroll;
  width: 100%;
}

.scrollableTableContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.simpleButton {
  background: white;
  border: 1px solid var(--slate-15);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.simpleButton:hover {
  cursor: pointer;
  background: var(--slate-05);
  border: 1px solid var(--slate-15);
}

.simpleButton.disabled,
.simpleButton.disabled:hover {
  background: var(--slate-05);
  cursor: default;
  border: 1px solid var(--slate-05);
}
