.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.content {
  position: absolute;
  width: 640px;
  height: 416px;
  left: 50%;
  top: 207px;
  transform: translate(-50%, 0%);
  background: var(--white);
  border-radius: 16px;
  outline: none;
}

.container {
  margin: 72px 120px;
  width: 400px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
