.logo {
  margin-bottom: 32px;
}

.headerText {
  margin-bottom: 16px;
  max-width: 560px;
  font-weight: normal;
  text-align: center;
}

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-end;
}

.incorrectCode {
  width: 347px;
  background: var(--razz-20);
  border-radius: 4px;
  padding: 12px 26px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.infoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrBox {
  width: 200px;
  height: 200px;
  margin-bottom: 48px;
  border: 2px solid var(--slate-15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authSuccess {
  width: 214px;
  height: 24px;
  background: var(--apple-20);
  color: var(--slate-darken-20);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
}
