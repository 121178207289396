.container,
.spacer {
  padding-left: var(--sidebarPaddingHoriz);
  margin-right: var(--sidebarPaddingHoriz);
}

.container {
  background-color: var(--slate-05);
  --paddingVert: 40px;
  padding-top: var(--paddingVert);
  padding-bottom: var(--paddingVert);
  height: calc(100vh - 2 * var(--paddingVert));
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  width: var(--sidebarContainerWidth);
  min-width: var(--sidebarContainerWidth);
  max-width: var(--sidebarContainerWidth);
}

.spacer {
  width: var(--sidebarSpacerWidth);
  min-width: var(--sidebarSpacerWidth);
  max-width: var(--sidebarSpacerWidth);
}

.label {
  padding: 14px;
  display: flex;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  color: var(--slate-85);
  margin-bottom: 2px;
}

.label:hover {
  background: var(--slate-15);
}

.label.chosen {
  background: var(--slate-15);
  color: var(--slate-darken-20);
}

.label path {
  fill: var(--slate-85);
}

.label.chosen path {
  fill: var(--slate-darken-20);
}

.title {
  color: inherit;
}

.refreshButton {
  border: 1px solid var(--slate-35);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 135%;
  padding: 6px 12px;
  width: fit-content;
  color: var(--slate-85);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.refreshButton:hover {
  cursor: pointer;
  background: var(--slate-15);
}
