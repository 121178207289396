.container {
  border-radius: 4px;
  background-color: white;
  display: flex;
  width: 100%;
}

.cell {
  /* This color was custom in design, asking Kim */
  border: 2px solid var(--slate-15);
  width: 200px;
  text-align: center;
  padding: 8px 0;
}

.cell:hover {
  cursor: pointer;
}

.cell.leftCell {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
}

.cell.rightCell {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cell.selected {
  border: 2px solid var(--eden);
  background-color: var(--apple-20);
}

.cell.leftOfSelected {
  border-left: none;
}
