.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  --modal-vert-padding: 66px;
  --modal-horiz-padding: 65px;
  max-width: calc(543px - 2 * var(--modal-horiz-padding));
  background: var(--white);
  border-radius: 16px;
  max-height: calc(100vh - 80px - 2 * var(--modal-vert-padding));
  overflow-y: hidden;
  outline: none;
  padding: var(--modal-vert-padding) var(--modal-horiz-padding);
  text-align: center;
  position: static;
}

.options {
  max-height: 40vh;
  overflow-y: scroll;
  border-radius: 6px;
  border: var(--slate-15) 1px solid;
  padding: 26px 18px;
  margin: 0 0 28px 0;
}

.form {
  width: 100%;
  margin: auto;
}

.xicon {
  position: relative;
  right: -22px;
  top: -32px;
  width: fit-content;
  height: 0;
  float: right;
}

.xicon:hover {
  cursor: pointer;
}

.answerBubble {
  padding: 8px 16px;
  background-color: var(--apple-20);
  border-radius: 1000px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 8px;
}

.answerContainer {
  display: flex;
  margin-top: 4px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.bubbleX:hover {
  cursor: pointer;
}
