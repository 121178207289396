.cover {
  z-index: 1001;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.5;
  position: absolute;
}

.container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1002;
}

.flyout {
  --paddingVert: 36px;
  position: absolute;
  z-index: 1003;
  height: calc(100vh - 2 * var(--paddingVert));
  right: 0;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: var(--paddingVert) 32px;
  min-width: 500px;
  width: 500px;
  overflow-y: scroll;
}

.iconParagraph {
  font-size: 14px;
  color: var(--slate-darken-20);
  display: flex;
  padding: 14px 20px;
}

.iconParagraph > div,
.iconParagraph > svg {
  margin: 3px 8px 0 0;
  flex-shrink: 0;
}

.strippedTable {
  border: none;
  width: 100%;
  margin-bottom: 32px;
  margin-left: 8px;
}

.strippedTable tr:not(:last-child) div {
  margin-bottom: 8px;
}

.strippedTable td:last-child div {
  font-weight: var(--semibold);
  color: var(--slate-darken-20);
}

.strippedTable > tr > td:last-child {
  font-weight: var(--semibold);
}

.x:hover {
  cursor: pointer;
}

.table {
  width: 100%;
}

.tableSelectorContainer {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
}

.tableSelect,
.tableSelectActive {
  margin: 0px 8px 16px 0px;
  height: 28px;
  border-radius: 100px;
  border: none;
  outline: none;
  background: var(--white);
}

.tableSelect:hover {
  background: var(--slate-05);
}

.tableSelectActive {
  background: var(--slate-darken-20);
}

.tableSelectText,
.tableSelectTextActive {
  padding: 0px 8px 0px 8px;
  font-weight: var(--bold);
  color: var(--slate-85);
}

.tableSelectTextActive {
  color: var(--white);
}
