.dropdown,
.dropdownButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdownButton {
  background: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  border-width: 0;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
}

.dropdownButtonActive {
  background: var(--ocean);
}

.dropdownButtonText {
  font-weight: var(--semibold);
  margin-right: 8px;
}

.arrow {
  height: 24px;
  width: 24px;
  margin-left: 8px;
  border-radius: 4px;
}

.arrow:hover,
.arrow.open {
  background: var(--slate-15);
}

.modalOuter {
  position: absolute;
  height: 0px;
  z-index: 1001;
}

.modal {
  min-width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  vertical-align: center;
  margin-top: 8px;
}

.kicker {
  height: 20px;
  text-align: center;
  font-weight: bold;
  padding: 8px 12px;
}

.divider {
  margin-top: 8px;
  margin-bottom: 8px;
  height: 1px;
  width: 100%;
  width: calc(100% - 3px);
  border-radius: 1px;
  background: var(--slate-15);
  align-self: center;
}

.item {
  height: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  width: calc(100% - 24px);
}

.item:hover {
  background: var(--slate-05);
  cursor: pointer;
}
