.container {
  border-radius: 100px !important;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  width: auto;
}

.generic {
  border-radius: 6px;
  color: var(--slate);
  padding: 0 20px;
}

.generic a {
  color: var(--eden);
  text-decoration: none;
}

.success {
  background-color: var(--apple-40);
}

.failure {
  background-color: var(--razz-20);
}

.iconWrapper svg {
  margin-bottom: -3px;
  margin-right: 8px;
}
