body {
  overscroll-behavior: none;
}

.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-end;
}

.bgContainer {
  background: linear-gradient(144.58deg, #14b87c 0%, #a8e6c2 100%);
  width: 100%;
  height: 100%;
  z-index: 0;
  position: fixed;
}

.bg {
  background-image: url(../../../assets/svg/loginBG.svg);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.panel {
  --panel-padding-width: 120px;
  --padding-top: 120px;
  --padding-bottom: 64px;
  position: relative;
  z-index: 1;
  min-width: 400px;
  max-width: calc(400px + 2 * var(--panel-padding-width));
  width: calc(50vw - 2 * var(--panel-padding-width));
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  background: white;
  padding: var(--padding-top) var(--panel-padding-width) var(--padding-bottom);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  margin-left: -16px;
  margin-bottom: 40px;
}

.footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 64px;
}

.form {
  width: 400px;
}

.error {
  background: var(--razz-20);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
